import React from 'react'

import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import { SEO } from '../components'
import { RainbowContainer } from '../components/nav/styled'
import { ProductList } from '../components/product'

const Products = ({ data, path }: any) => {
  const { t } = useTranslation()
  return (
    <>
      <SEO
        title={t(data.shopifyCollection.title)}
        description={t(data.shopifyCollection.description)}
        image={data.shopifyCollection?.image?.src}
        keywords={[t(data.shopifyCollection.title)]}
        path={path}
      />
      <RainbowContainer>{t('bym.worldwide')}</RainbowContainer>
      <ProductList
        title={data.shopifyCollection.title}
        products={data.shopifyCollection.products}
        withFilter
        withCollection={data.shopifyCollection}
      />
    </>
  )
}

export default Products

export const query = graphql`
  query ($handle: String!) {
    shopifyCollection(handle: { eq: $handle }) {
      title
      description
      image {
        src
      }
      handle
      products {
        id
        title
        handle
        productType
        description
        shopifyId
        availableForSale
        options {
          name
          values
        }
        variants {
          id
          title
          price
          compareAtPrice
          availableForSale
          shopifyId
          selectedOptions {
            name
            value
          }
          image {
            originalSrc
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        images {
          originalSrc
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
